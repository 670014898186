.loginForm-vertical-center {
	min-height: 100%; /* Fallback for browsers do NOT support vh unit */
	min-height: 100vh; /* These two lines are counted as one :-)       */

	display: flex;
	align-items: center;
}
.loginForm-overflow-police {
	overflow: hidden !important;
}
