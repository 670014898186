.dropzone.dz-clickable {
	cursor: pointer;
}
.dropzone {
	min-height: 300px;
	border: 2px dashed rgba(0, 0, 0, 0.3);
	background: 0 0;
	padding: 20px 20px;
}
.dropzone,
.dropzone * {
	box-sizing: border-box;
}
form {
	display: block;
	margin-top: 0em;
}
.dropzone .dz-message .dz-button {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}
.dropzone.dz-clickable .dz-message,
.dropzone.dz-clickable .dz-message * {
	cursor: pointer;
}
.testcc {
	width: 0;
}
.cardEnveloppe {
	background-color: #c9c9c9;
	border: none;
}
