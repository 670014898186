.edit-profile-vertical-center {
    margin-top: 25px;
}

.edit-profile-padding {
    padding-bottom: 10px;
}

.edit-profile-center {
    display: block;
    text-align: center;
}

.edit-profile-pad {
    margin-bottom: 25px;
}

.edit-profile-upload-image-btn {
    margin-top: 10px;
}

.edit-profile-token-blur:not(:focus) {
    color: transparent;
    text-shadow: 0 0 5px rgba(0,0,0,0.5);
}

.edit-profile-upload-btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    cursor: pointer;
}
