.profile-vertical-center {
    top: 25%;
}

.profile-center {
    display: block;
    text-align: center;
}

.profile-pad {
    margin-bottom: 25px;
}

.profile-name-top-padding {
    padding-top: 10px;
}
.card-border-size{
    border-width: 3px;
}